<template>
    <div>
        Login: {{ token }}
    </div>
</template>

<script>
import { loginByToken } from "../services/user";
import { setMvpToken, setToken } from "../utils/auth";

export default {
  name: "mvpAuthentication",
  data() {
    return {
        token: null
    }
  },
  mounted() {
    if(this.$route.query.token) {
      this.token = this.$route.query.token
    }
    if(this.token) {
      loginByToken(this.token).then(res => {
        setMvpToken(this.token)
        setToken(res.token)
        this.$router.push('/')
      })
    } else {
      this.$router.push('/mvpLogin')
    }
  }
};
</script>

<style scoped></style>
